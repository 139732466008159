<template>
  <ProductsView v-if="servicesContent && services" :content="servicesContent" :products="services" />
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import ProductsView from '@/components/ProductsView'

export default {
  name: 'Services',
  title: 'Servicios | Andinor',
  components: { ProductsView },
  computed: {
    ...mapState(['servicesContent']),
    ...mapGetters(['services'])
  },
  created () {
    this.getServicesContent()
  },
  methods: {
    ...mapActions(['getServicesContent'])
  }
}
</script>
